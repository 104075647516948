import { Link } from "gatsby"
import React from "react"
import logo from "../images/distanz.png"
import "../components/nav.css"

const toggleMobileNav = () => {
  const menu = document.getElementById("links")
  menu.classList.toggle("active")
}

const Nav = () => (
  <nav>
    <div className="nav">
      <a className="logocontainer" href="/">
        <img src={logo} alt="Distanz Logo" id="logo" className="logo"></img>
      </a>
      <div id="toggle" className="toggle" onClick={toggleMobileNav}>
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </div>
      <ul id="links" className="links">
        <li>
          <Link className="link" to="/text-audio">
            Texte und Audios
          </Link>
        </li>
        {/* <li>
          <Link className="link" to="/veranstaltungen">
            Veranstaltungen
          </Link>
        </li> */}
        <li>
          <Link className="link" to="/ueber-uns">
            Über uns
          </Link>
        </li>
      </ul>
    </div>
  </nav>
)

export default Nav
